<template>
  <v-card
    :width="
      inviteLoading
        ? 700
        : requiresLogin
        ? 550
        : requiresConsent
        ? 800
        : invalidInvite
        ? 600
        : 800
    "
    class="mx-auto smc-card-dialog"
  >
    <v-card-title
      class="
        primary
        white--text
        text-center
        smc-card-dialog-title smc-card-dialog-title-narrow
      "
    >
      <span class="mx-auto">
        {{
          inviteLoading
            ? "LOADING"
            : requiresLogin
            ? "LOGIN"
            : requiresConsent
            ? "ADD POLICY"
            : invalidInvite
            ? "NOTICE"
            : searchInvite
            ? "SEARCH"
            : "REGISTER"
        }}
      </span>
    </v-card-title>
    <v-card-text v-if="inviteLoading" class="smc-card-loading">
      <car-driving />
    </v-card-text>
    <!-- Login -->
    <v-card-text v-else-if="requiresLogin">
      <ValidationObserver ref="loginValidationObserver">
        <v-form
          v-model="loginFormValid"
          ref="loginForm"
          lazy-validation
          @submit="submitLogin($event)"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="Email address"
            rules="required|email"
          >
            <v-text-field
              id="login-email"
              v-model="user.username"
              :readonly="user.usernameReadonly"
              :disabled="user.usernameReadonly"
              :error-messages="errors"
              prepend-inner-icon="mdi-account-circle"
              label="Email Address"
              outlined
              rounded
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            rules="required"
          >
            <v-text-field
              id="login-password"
              v-model="user.password"
              :error-messages="errors"
              prepend-inner-icon="mdi-lock"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="passwordVisible ? 'text' : 'password'"
              name="password"
              label="Password"
              outlined
              rounded
              @click:append="passwordVisible = !passwordVisible"
            />
          </ValidationProvider>
          <v-divider></v-divider>
          <v-expand-transition>
            <v-row v-if="errorMessage" class="py-3">
              <v-col>
                <span class="error-label error--text">
                  {{ errorMessage }}
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-divider v-if="errorMessage"></v-divider>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  id="login-submit"
                  :disabled="!loginFormValid"
                  color="primary"
                  type="submit"
                  rounded
                  :loading="loading"
                  class="smc-medium-button mt-2"
                >
                  Login
                </v-btn>
              </v-col>
              <v-spacer
                v-if="hasGoogleLogin"
                class="d-none d-sm-flex"
              ></v-spacer>
              <v-col v-if="hasGoogleLogin">
                <v-btn
                  id="login-signinGoogle"
                  color="info"
                  @click="signinGoogle"
                  text
                  height="50"
                  class="smc-text-button pa-0"
                >
                  <v-img
                    width="200"
                    src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png"
                    lazy-src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
                  ></v-img>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="mx-auto py-1">
                Having trouble loggin in?
                <v-btn
                  id="login-forgotPassword"
                  text
                  rounded
                  :to="`/account/forgot-password${
                    returnUrlProp ? '?returnUrl=' + returnUrlProp : ''
                  }`"
                  class="primary--text text-none smc-text-button"
                >
                  Forgot password
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <!-- Logged In Consent -->
    <v-card-text v-else-if="requiresConsent">
      <ValidationObserver ref="consentValidationObserver">
        <v-form ref="consentForm" @submit="submitConsent($event)">
          Add the following innovation policy to your profile:

          <vehicle-summary
            v-bind:vehicleProp="vehicle"
            v-bind:policyProp="policy"
          >
          </vehicle-summary>
          <v-card-actions>
            <p class="mx-auto mt-4">
              By clicking ADD POLICY, you agree to some terms and conditions or
              another, probably.
            </p>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-dialog
                  v-model="consentDialogShow"
                  max-width="400"
                  transition="dialog-top-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="register-submit"
                      color="primary"
                      :loading="loading"
                      rounded
                      class="mx-auto smc-medium-button mt-2"
                      :disabled="consentSubmitButtonDisabled"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Add Policy
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="primary" dark height="48" class="mb-6">
                      Confirm
                    </v-toolbar>
                    <v-card-text>
                      Are you sure you want to add this policy to your account?
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        rounded
                        class="smc-text-button smc-small-button"
                        color="secondary"
                        @click="consentDialogShow = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        class="smc-text-button smc-small-button"
                        color="primary"
                        type="submit"
                        @click="submitConsent"
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="consentSuccessShow">
            <v-row>
              <v-col>
                <v-alert
                  dense
                  outlined
                  type="success"
                  border="left"
                  class="mb-0"
                >
                  Policy added to account successfully
                </v-alert>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="consentSuccessShow">
            <v-row>
              <v-col>
                Go to
                <v-btn to="/my-garage" color="primary" rounded>My Garage</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <!-- Invite Error -->
    <v-card-text v-else-if="invalidInvite">
      {{ invalidInviteError }}
    </v-card-text>
    <!-- Register -->
    <v-card-text v-else-if="inviteLoaded">
      <ValidationObserver ref="registerValidationObserver">
        <v-form
          v-model="registerFormValid"
          ref="registerForm"
          lazy-validation
          @submit="submitRegister($event)"
        >
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="First name"
                rules="required"
              >
                <v-text-field
                  id="register-firstName"
                  v-model="user.firstName"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-account-edit"
                  :disabled="registrationSuccess"
                  label="First name"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Last name"
                rules="required"
              >
                <v-text-field
                  id="register-lastName"
                  v-model="user.lastName"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-account-edit"
                  :disabled="registrationSuccess"
                  label="Last name"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email address"
                rules="required|email"
              >
                <v-text-field
                  id="register-email"
                  v-model="user.username"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-email"
                  :disabled="registrationSuccess"
                  label="Email address"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Mobile number"
                rules="required|phone"
              >
                <v-text-field
                  id="register-phone"
                  v-model="user.phoneNumber"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-cellphone"
                  :disabled="registrationSuccess"
                  label="Mobile number"
                  v-mask="'### ### ####'"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                rules="required|password|min:8"
              >
                <v-text-field
                  id="register-password"
                  v-model="user.password"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  :disabled="registrationSuccess"
                  name="password"
                  label="Password"
                  hint="At least 8 characters"
                  @click:append="passwordVisible = !passwordVisible"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Confirm Password"
                rules="required|confirmedBy:@Password"
              >
                <v-text-field
                  id="register-confirmPassword"
                  v-model="user.confirmPassword"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-lock-reset"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  :disabled="registrationSuccess"
                  name="confirmPassword"
                  label="Confirm Password"
                  hint="Password and confirm password must match"
                  @click:append="passwordVisible != passwordVisible"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-expand-transition>
            <v-row v-if="errorMessage" class="py-3">
              <v-col>
                <span class="error-label error--text" v-html="errorMessage">
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-divider v-if="errorMessage"></v-divider>
          <v-expand-transition>
            <v-row v-if="registrationSuccess" class="py-3">
              <v-col>
                <span class="success-label success--text">
                  Registration successful.
                  <br />
                  Please look at your email for further instructions.
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-divider v-if="registrationSuccess"></v-divider>
          <vehicle-summary
            v-bind:vehicleProp="vehicle"
            v-bind:policyProp="policy"
          >
          </vehicle-summary>
          <v-card-actions>
            <p class="mx-auto mt-4">
              By clicking SUBMIT, you agree to our terms & conditions, privacy
              policy and cookie policy.
              <br />You may receive email notifications from us.
            </p>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  id="register-submit"
                  color="primary"
                  :disabled="!registerFormValid || registrationSuccess"
                  :loading="loading"
                  type="submit"
                  rounded
                  class="mx-auto smc-medium-button mt-2"
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  id="login-signinGoogle"
                  color="info"
                  @click="signinGoogle"
                  :disabled="registrationSuccess"
                  text
                  height="50"
                  class="smc-text-button pa-0"
                >
                  <v-img
                    width="200"
                    src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png"
                    lazy-src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
                  ></v-img>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <!-- Search -->
    <v-card-text v-else-if="searchInvite">
      <ValidationObserver ref="searchValidationObserver">
        <v-form ref="searchForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" offset-md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email address"
                rules="email"
              >
                <v-text-field
                  id="search-email"
                  v-model="searchEmail"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-email"
                  label="Email address"
                  rounded
                  outlined
                  hide-details="auto"
                  :disabled="searchPhone !== '' || searchPolicyCode !== ''"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip color="secondary">or</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" offset-md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="Mobile number"
                rules="phone"
              >
                <v-text-field
                  id="search-phone"
                  v-model="searchPhone"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-cellphone"
                  label="Mobile number"
                  v-mask="'### ### ####'"
                  rounded
                  outlined
                  hide-details="auto"
                  :disabled="searchEmail !== '' || searchPolicyCode !== ''"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip color="secondary">or</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" offset-md="3">
              <ValidationProvider v-slot="{ errors }" name="Policy code">
                <v-text-field
                  id="search-policy-code"
                  v-model="searchPolicyCode"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-note"
                  label="Policy code"
                  rounded
                  outlined
                  hide-details="auto"
                  :disabled="searchEmail !== '' || searchPhone !== ''"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row v-if="noSearchError">
              <v-col>
                <v-alert class="mb-0" border="left" type="error">
                  Please complete at least one entry to search.
                </v-alert>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row v-if="inviteFound">
              <v-col>
                <v-alert class="mb-0" border="left" type="success">
                  Success! Please check your phone for the invite link.
                </v-alert>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row v-if="inviteNotFound">
              <v-col>
                <v-alert class="mb-0" border="left" type="error">
                  The invite could not be found. Please try searching with different details.
                </v-alert>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-row>
            <v-col class="text-center">
              <v-btn
                color="primary"
                type="submit"
                rounded
                @click="submitSearch"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <!-- Unexpected Error -->
    <v-card-text v-else> Something went wrong. </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import carDriving from "@/components/widgets/car-driving.vue";
import vehicleSummary from "@/components/vehicle-summary.vue";
import { authService } from "@/services";
import { authStatuses } from "@/helpers";
import { mask } from "vue-the-mask";
import validate from "@/mixins/validator";
import AuthenticateLogin from "@/models/authenticate-login";

export default {
  name: "invite",
  directives: {
    mask,
  },
  mixins: [validate],
  components: {
    carDriving,
    vehicleSummary,
  },
  props: {
    inviteIdProp: String,
  },
  data: () => ({
    inviteLoading: false,
    registerFormValid: false,
    loginFormValid: false,
    returnUrlProp: window.location.pathname,

    loading: false,
    registrationSuccess: false,

    searchInvite: false,
    noSearchError: false,
    inviteNotFound: false,
    inviteFound: false,
    searchEmail: "",
    searchPhone: "",
    searchPolicyCode: "",

    invite: undefined,
    inviteLoaded: false,
    vehicle: undefined,
    policy: undefined,

    invalidInvite: false,
    invalidInviteError: "",
    requiresConsent: false,
    requiresLogin: false,
    hasGoogleLogin: false,
    hasPasswordLogin: false,

    user: {
      firstName: "",
      lastName: "",
      username: "",
      usernameReadonly: false,
      phoneNumber: "",
      phoneNumberReadonly: false,
      password: "",
      confirmPassword: "",
    },
    passwordVisible: false,
    errorMessage: "",

    consentDialogShow: false,
    consentSubmitButtonDisabled: false,
    consentSuccessShow: false,
  }),
  computed: {
    userModel() {
      if (this.isAuthenticated) {
        return this.$store.getters["user/getUserModel"];
      }
      return undefined;
    },
    isAuthenticated() {
      return this.$store.getters["user/isAuthenticated"];
    },
  },
  async created() {
    if (this.inviteIdProp) {
      await this.loadInvite();
    } else {
      this.searchInvite = true;
    }
  },
  methods: {
    async loadInvite() {
      this.inviteLoading = true;
      this.inviteLoaded = false;
      let loggedInUserId = 0;
      if (this.isAuthenticated) {
        if (!this.userModel.id) {
          await this.$store.dispatch("user/loadUser");
        }
        loggedInUserId = this.userModel.id;
      }

      try {
        var result = await authService.getInvite(
          this.inviteIdProp,
          loggedInUserId
        );

        this.inviteLoaded = true;
        this.user.firstName = result.firstName;
        this.user.lastName = result.lastName;
        this.user.username = result.email;
        this.user.usernameReadonly = result.emailReadonly;
        this.user.phoneNumber = result.phoneNumber;
        this.user.phoneNumberReadonly = result.phoneNumberReadonly;

        this.invalidInvite = result.invalidInvite;
        this.invalidInviteError = result.invalidInviteError;
        this.requiresLogin = result.requiresLogin;
        this.requiresConsent = result.requiresConsent;

        this.hasPasswordLogin = result.hasPasswordLogin;
        this.hasGoogleLogin = result.hasGoogleLogin;

        this.policy = result.policy;
        if (result.policy && result.policy.customerVehicleMapping) {
          this.vehicle = result.policy.customerVehicleMapping.vehicle;
        }
      } catch (error) {
        console.error(error);
      }
      this.inviteLoading = false;
    },
    async submitLogin($event) {
      $event.preventDefault();

      if (await this.$refs.loginValidationObserver.validate()) {
        this.loading = true;
        this.passwordVisible = false;

        var result = await authService.authenticate(
          new AuthenticateLogin(
            _.trim(this.user.username),
            _.trim(this.user.username),
            this.user.password
          )
        );

        if (result.status == 200 || result.status == 401) {
          switch (result.data.status) {
            case authStatuses.success:
              this.$store.dispatch("user/setUser", {
                username: this.username,
                email: this.username,
                isLoaded: false,
              });

              this.$store.dispatch("user/setToken", {
                accessToken: {
                  token: result.data.accessToken,
                  tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                  tokenExpires: result.data.accessTokenExpires,
                },
                refreshToken: {
                  token: result.data.refreshToken,
                  tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                  tokenExpires: result.data.refreshTokenExpires,
                },
              });

              //if (this.returnUrlProp) {
              //  this.$router.push(this.returnUrlProp);
              //} else {
              //  this.$router.push({
              //    name: "home",
              //  });
              //}
              await this.loadInvite();
              break;
            case authStatuses.failed:
              this.errorMessage = "Login failed.";
              this.loading = false;
              break;
            case authStatuses.emailConfirmationRequired:
              this.errorMessage =
                "Unable to log in. Please check your email for more details.";
              this.loading = false;
              break;
            case authStatuses.newPasswordRequired:
              this.passwordRequired = true;
              this.loading = false;
              break;
            case authStatuses.lockedOut:
              this.errorMessage =
                "Account locked. Please try again in a few minutes.";
              this.loading = false;
              break;
            case authStatuses.notAllowed:
              this.errorMessage = "Login failed.";
              this.loading = false;
              break;
            case authStatuses.requiresTwoFactor:
          }
        } else {
          this.errorMessage = "Something went wrong.";
        }
      }

      this.loading = false;
    },
    async submitRegister($event) {
      $event.preventDefault();
      if (await this.$refs.registerValidationObserver.validate()) {
        this.errorMessage = "";
        this.loading = true;
        this.passwordVisible = false;
        var result = await authService.inviteRegister(this.inviteIdProp, {
          username: _.trim(this.user.username),
          password: this.user.password,
          email: _.trim(this.user.username),
          phoneNumber: this.user.phoneNumber,
          applicationUser: {
            email: _.trim(this.user.username),
            firstName: _.trim(this.user.firstName),
            lastName: _.trim(this.user.lastName),
            phone: this.user.phoneNumber,
          },
        });

        if (result.status == 200) {
          this.registrationSuccess = true;
        } else if (result.status == 400) {
          if (result.data.User) {
            var validationMessages = [];
            _.mapKeys(result.data.User, function (value) {
              validationMessages.push(`<span>${value}</span>`);
            });
          }

          this.errorMessage = `<div>${_.map(validationMessages).join(
            ""
          )}</div>`;
        } else {
          this.errorMessage = "Something went wrong.";
        }
      }
      this.loading = false;
    },
    async submitConsent($event) {
      $event.preventDefault();
      var result = await authService.consentInvite(this.inviteIdProp);
      await this.$store.dispatch("user/loadUser");
      if (result.status == 200) {
        this.consentDialogShow = false;
        this.consentSubmitButtonDisabled = true;
        this.consentSuccessShow = true;
      }
    },
    async signinGoogle() {
      const googleUser = await this.$gAuth.signIn();
      if (!googleUser) {
        return null;
      }
      let authResponse = googleUser.getAuthResponse();
      let email = googleUser.getBasicProfile().getEmail();

      this.loading = true;
      var result = await authService.externalAuthenticate({
        idToken: authResponse.id_token,
        provider: "google",
      });

      if (result.status == 200 && result.data.status == authStatuses.success) {
        this.$store.dispatch("user/setUser", {
          username: email,
          email: email,
          isLoaded: false,
        });

        this.$store.dispatch("user/setToken", {
          accessToken: {
            token: result.data.accessToken,
            tokenExpiresUtc: result.data.accessTokenExpiresUtc,
            tokenExpires: result.data.accessTokenExpires,
          },
          refreshToken: {
            token: result.data.refreshToken,
            tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
            tokenExpires: result.data.refreshTokenExpires,
          },
        });

        await this.loadInvite();
      } else {
        this.errorMessage = "Something went wrong.";
      }
      this.loading = false;
    },
    async submitSearch($event) {
      $event.preventDefault();

      if (!this.searchEmail && !this.searchPhone && !this.searchPolicyCode) {
        this.noSearchError = true;
        return;
      }

      this.noSearchError = false;
      this.inviteFound = false;
      this.inviteNotFound = false;
      if (await this.$refs.searchValidationObserver.validate()) {
        var result = await authService.searchInvite({
          email: this.searchEmail,
          phone: this.searchPhone,
          policyCode: this.searchPolicyCode,
        });

        if (result.status == 200 && result.data.result) {
         this.inviteFound = true;
        }
        else {
          this.inviteNotFound = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}

.smc-card-loading {
  padding: 0;
  margin-top: -50px;
  border-radius: 30px;
  overflow: hidden;
}
</style>