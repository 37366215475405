<template>
  <v-card width="700" class="mx-auto smc-card-dialog">
    <v-card-title
      class="
        primary
        white--text
        text-center
        smc-card-dialog-title smc-card-dialog-title-narrow
      "
    >
      <span class="mx-auto">REGISTER</span>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="validationObserver">
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          @submit="submit($event)"
        >
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="First name"
                rules="required"
              >
                <v-text-field
                  id="register-firstName"
                  v-model="firstName"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-account-edit"
                  :disabled="registrationSuccess"
                  label="First name"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Last name"
                rules="required"
              >
                <v-text-field
                  id="register-lastName"
                  v-model="lastName"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-account-edit"
                  :disabled="registrationSuccess"
                  label="Last name"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email address"
                rules="required|email"
              >
                <v-text-field
                  id="register-email"
                  v-model="username"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-email"
                  :disabled="registrationSuccess"
                  label="Email address"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Mobile number"
                rules="required|phone"
              >
                <v-text-field
                  id="register-phone"
                  v-model="phoneNumber"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-cellphone"
                  :disabled="registrationSuccess"
                  label="Mobile number"
                  v-mask="'### ### ####'"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5" class="py-0 pl-6">
              <v-switch
                class="mt-0"
                label="Capture business details"
                v-model="isBusiness"
                :disabled="registrationSuccess"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="7" class="py-0">
              <p>
                Capturing business details is optional. These details will
                appear on all applicable quotes and invoices.
              </p>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row v-if="isBusiness">
              <v-col cols="12" md="12" class="pb-8">
                <v-card class="smc-card-dialog mt-0">
                  <v-card-title
                    class="smc-card-dialog-title-inset primary white--text py-1"
                  >
                    <span class="mx-auto">Business details</span>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <v-row>
                      <v-col cols="12" class="px-8">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Business name"
                          :rules="isBusiness ? 'required' : ''"
                        >
                          <v-text-field
                            id="register-businessName"
                            v-model="businessName"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-domain"
                            :disabled="registrationSuccess"
                            label="Business name"
                            rounded
                            outlined
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'px-8'"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Business registration number"
                          rules=""
                        >
                          <v-text-field
                            id="register-businessRegistrationNumber"
                            v-model="businessRegistrationNumber"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-domain"
                            :disabled="registrationSuccess"
                            label="Business registration"
                            rounded
                            outlined
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                        :class="$vuetify.breakpoint.mdAndUp ? 'pr-8' : 'px-8'"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Business VAT number"
                          rules=""
                        >
                          <v-text-field
                            id="register-businessTaxNumber"
                            v-model="businessTaxNumber"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-domain"
                            :disabled="registrationSuccess"
                            label="Business VAT number"
                            rounded
                            outlined
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" class="px-8 py-0">
                        <address-capture
                          v-bind:disabledProp="registrationSuccess"
                          v-bind:allowManualCaptureProp="true"
                          v-bind:completeAddressRequiredProp="true"
                          v-on:addressChange="updateAddress($event)"
                        ></address-capture>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                rules="required|password|min:8"
              >
                <v-text-field
                  id="register-password"
                  v-model="password"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  :disabled="registrationSuccess"
                  name="password"
                  label="Password"
                  hint="At least 8 characters"
                  @click:append="passwordVisible = !passwordVisible"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Confirm Password"
                rules="required|confirmedBy:@Password"
              >
                <v-text-field
                  id="register-confirmPassword"
                  v-model="confirmPassword"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-lock-reset"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  :disabled="registrationSuccess"
                  name="confirmPassword"
                  label="Confirm Password"
                  hint="Password and confirm password must match"
                  @click:append="passwordVisible != passwordVisible"
                  rounded
                  outlined
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-expand-transition>
            <v-row v-if="errorMessage" class="py-3">
              <v-col>
                <span class="error-label error--text" v-html="errorMessage">
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-divider v-if="errorMessage"></v-divider>
          <v-expand-transition>
            <v-row v-if="registrationSuccess" class="py-3">
              <v-col>
                <span class="success-label success--text">
                  Registration successful.
                  <br />
                  Please look at your email for further instructions.
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-divider v-if="registrationSuccess"></v-divider>
          <v-card-actions>
            <p class="mx-auto">
              By clicking SUBMIT, you agree to our terms & conditions, privacy
              policy and cookie policy.
              <br />
              You may receive email notifications from us.
            </p>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  id="register-submit"
                  color="primary"
                  :disabled="!valid || registrationSuccess"
                  :loading="loading"
                  type="submit"
                  rounded
                  class="mx-auto smc-medium-button mt-2"
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  id="login-signinGoogle"
                  color="info"
                  @click="signinGoogle"
                  :disabled="registrationSuccess"
                  text
                  height="50"
                  class="smc-text-button pa-0"
                >
                  <v-img
                    width="200"
                    src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png"
                    lazy-src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
                  ></v-img>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import addressCapture from "@/components/address-capture.vue";
import { authService } from "@/services";
import { authStatuses } from "@/helpers";
import { mask } from "vue-the-mask";
import validate from "@/mixins/validator";

export default {
  name: "signup",
  directives: {
    mask,
  },
  components: {
    addressCapture,
  },
  mixins: [validate],
  data() {
    return {
      valid: false,
      loading: false,
      registrationSuccess: false,
      errorMessage: "",
      firstName: "",
      lastName: "",
      username: "",
      phoneNumber: "",
      isBusiness: false,
      businessName: "",
      businessRegistrationNumber: "",
      businessTaxNumber: "",
      businessAddress: {},
      password: "",
      confirmPassword: "",
      passwordVisible: false,
    };
  },
  watch: {
    username: function () {
      this.username = _.trim(this.username);
    },
  },
  methods: {
    updateAddress(address) {
      this.businessAddress = address;
    },
    async submit($event) {
      $event.preventDefault();
      this.errorMessage = "";

      if (await this.$refs.validationObserver.validate()) {
        this.loading = true;

        var result = await authService.register({
          username: _.trim(this.username),
          password: this.password,
          email: _.trim(this.username),
          phoneNumber: this.phoneNumber,
          applicationUser: {
            email: _.trim(this.username),
            firstName: _.trim(this.firstName),
            lastName: _.trim(this.lastName),
            phone: this.phoneNumber,
            businessName: this.isBusiness ? _.trim(this.businessName) : "",
            businessRegistrationNumber: this.isBusiness
              ? _.trim(this.businessRegistrationNumber)
              : "",
            businessTaxNumber: this.isBusiness
              ? _.trim(this.businessTaxNumber)
              : "",
            businessAddress: this.isBusiness ? this.businessAddress : null,
          },
        });

        if (result.status == 200) {
          this.registrationSuccess = true;
        } else if (result.status == 400) {
          if (result.data.User) {
            var validationMessages = [];
            _.mapKeys(result.data.User, function (value) {
              validationMessages.push(`<span>${value}</span>`);
            });
          }

          this.errorMessage = `<div>${_.map(validationMessages).join(
            ""
          )}</div>`;
        } else if (result.status == 422) {
          this.errorMessage = result.data;
        } else {
          this.errorMessage = "Something went wrong.";
        }
      }
      this.loading = false;
    },
    async signinGoogle() {
      const googleUser = await this.$gAuth.signIn();
      if (!googleUser) {
        return null;
      }
      let authResponse = googleUser.getAuthResponse();
      let email = googleUser.getBasicProfile().getEmail();

      this.loading = true;
      var result = await authService.externalAuthenticate({
        idToken: authResponse.id_token,
        provider: "google",
      });

      if (result.status == 200 && result.data.status == authStatuses.success) {
        this.$store.dispatch("user/setUser", {
          username: email,
          email: email,
          isLoaded: false,
        });

        this.$store.dispatch("user/setToken", {
          accessToken: {
            token: result.data.accessToken,
            tokenExpiresUtc: result.data.accessTokenExpiresUtc,
            tokenExpires: result.data.accessTokenExpires,
          },
          refreshToken: {
            token: result.data.refreshToken,
            tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
            tokenExpires: result.data.refreshTokenExpires,
          },
        });

        if (this.returnUrlProp) {
          this.$router.push(this.returnUrlProp);
        } else {
          this.$router.push({
            name: "home",
          });
        }
      } else {
        this.errorMessage = "Something went wrong.";
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}
</style>
