<template>
  <v-container class="position-relative">
    <v-banner
      color="info"
      class="pwa-banner text-left white--text"
      elevation="10"
    >
      Install our app for easy access in the future
      <template v-slot:actions>
        <v-btn class="white--text" outlined text @click="dismiss">
          Dismiss
        </v-btn>
        <v-btn class="white--text" outlined text @click="show = true">
          Install
        </v-btn>
        <v-dialog
          v-model="show"
          persistent
          scrollable
          content-class="smc-dialog"
        >
          <v-card class="smc-card-dialog" color="white">
            <v-card-title
              class="
                primary
                white--text
                text-center
                smc-card-dialog-title smc-card-dialog-title-narrow
              "
            >
              <span class="mx-auto">Install Instructions</span>
            </v-card-title>
            <div class="smc-card-top-bar">
              <v-btn
                icon
                color="midGrey"
                class="float-right"
                height="32"
                width="32"
                @click="show = false"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <p>On the website, tap <span class="NoBreak"><img src="@/assets/images/sharebutton.png" alt="the Share button" height="25" width="20" originalimagename="GlobalArt/IL_ShareBlue.png">, </span>
                scroll down the list of options, then tap <strong>Add to Home Screen.</strong>
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row class="my-1">
                <v-col>
                  <v-btn
                    color="primary"
                    rounded
                    class="smc-medium-button"
                    @click="show = false"
                  >
                    Okay
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-banner>
  </v-container>
</template>

<script>
export default {
  name: "install-pwa-banner",
  data: () => ({
    show: false,
  }),
  methods: {
    dismiss() {
      if (process.env.NODE_ENV === "production") {
        this.$cookies.set("pwa_install_dismiss", "dismiss", "15d");
      } else {
        this.$cookies.set("pwa_install_dismiss", "dismiss", "1d");
      }
      this.$store.dispatch("pwaInstall/clearInstallEvent");
    },
    install() {
      this.show = true;
    },
    hide() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.pwa-banner {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 15px;
}
.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  display: inline-flex;
  height: 68px;
}

.smc-card-dialog.v-card .smc-card-dialog-mid-heading {
  max-width: 265px;

  @media screen and (max-width: 960px) {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    padding: 15px 0;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.quote-total {
  width: 100%;
}

.certify-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.posh-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.smc-card-avatar a img {
  padding: 5px;
}

.v-expansion-panel.rounded-bottom,
.v-expansion-panel-header.rounded-bottom:not(.v-expansion-panel-header--active) {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

</style>
