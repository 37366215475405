export default class AuthenticateForgotPasswordConfirm {
    constructor(
        username,
        emailAddress,
        password,
        code
    ) {
        this.username = username;
        this.emailAddress = emailAddress;
        this.password = password;
        this.code = code;
    }
};